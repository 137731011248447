<template>
  <div>
    <div :class="`mb-10 ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1>Download our app for Android</h1>
      <p>
        Download our app to track your activities and steps. Get real-time updates on challenge progress and view the leaderboards and results.
      </p>
  
      <v-alert type="info">
        <p>Due to unexpected circumstances our of our control, the Android app is currently not available through the Play Store. We are working on getting this fixed.</p>
        <p>In the mean time, here are instructions to help you get started.</p>
      </v-alert>
      <h2>Use a web browser</h2>
      <p>
        The easiest way to track leaderboards is by using the web version of our platform. 
      </p>
      <p>
        The web platform allows you to connect most integrations, except Samsung Watch. Please follow the Apk instructions below in case you want to connect a Samsung Watch. You can also use the web platform for manual entries.
      </p>
      <p>
        <v-btn outlined color="primary" :to="profile">Open your profile</v-btn>
      </p>

      <h2>Download and install apk file</h2>
      <v-alert type="warning">Please note: this is for advanced users only. We recommend using the web browser or to wait until our app is available in the Play Store again.</v-alert>
      <ol class="mb-4">
        <li><a href="https://sodispstoragep.blob.core.windows.net/public/app/cofi-app-4.3.0-arm64-v8a-release.apk">Download the .apk file</a> on your Android phone</li>
        <li>Please note: some Android systems will rename the .apk extension to .zip. In order to open it, you'll have to rename it again and change .zip to .apk</li>
        <li>Open the downloaded apk file</li>
        <li>If you get a warning you need to change your settings to allow installation, please follow these instructions</li>
        <li>Click <strong>Install</strong></li>
        <li>Select <strong>View Details</strong> and <strong>Install without scanning</strong></li>
        <li>Click <strong>Open</strong> the app</li>
        <li>Now select the <strong>Scan QR code</strong> option to scan the join link and get started.</li>
      </ol>
      <p>You can now use the app like normal.</p>
    </div>

  </div>
</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>